<template>
    <login-layout>

        <template #heading>
            <div class="pos-r">
                <div>
                    <h2 class="text-white">Login to </h2>
                    <p class="font-inter-extra-light text-white fs-lg-7 m-0">Admin Portal</p>
                </div>
            </div>
        </template>

        <template #default>
            <div class="mt-4">

                <div class="text-center">
                    <h5 class="fs-lg-6 mb-1 font-inter-semi-bold">Forgot Password</h5>
                    <p class="font-inter-medium fs--1 ml-2 text-5 text-left">
                        Forgot your password? Please enter your username.
                    </p>
                </div>

                <b-form ref="contactForm" :save-url="addUrl" @success="formSuccess"
                        v-slot="{model, loading}">

                    <validated-input label="Username" placeholder="Enter your Username"
                                     v-model="model.username" class="text-secondary mb-1 c-input-1 c-form-input"
                                     :disabled="loading" :rules="{required: true}" name="Username"/>

                    <span class="text-danger" v-if="errorMessage" v-html="errorMessage"></span>

                    <p class="mb-4 text-right font-inter-medium text-5 link fs--1">
                        <span class="text-secondary ml-2"></span>
                    </p>

                    <div class="text-center">
                        <btn class="pl-5 pr-5 font-inter-medium" block text="Submit" loading-text="Validating..."
                             size=""
                             :disabled="loading" :loading="loading"/>
                    </div>

                    <p v-if="success" class="mt-4 text-center font-inter-medium fs--1 ml-2 text-5 text-left">
                        Please check your email. You will receive a link to create a new password.
                    </p>
                </b-form>

                <div class="mt-5 text-center">
                    <router-link to="/login/"
                                 class="text-primary fs--1 font-inter-medium text-decoration-none">
                        Back to Login
                    </router-link>
                </div>

            </div>

        </template>

    </login-layout>
</template>

<script>
import LoginLayout from '@/views/auth/LoginPageLayout';
import urls from '@/data/urls';

export default {
    name : 'ForgotPassword',

    components : { LoginLayout },

    data () {
        return {
            success      : false,
            errorMessage : '',
            addUrl       : urls.auth.generateResetLink
        };
    },
    methods : {

        formSuccess () {
            this.success = true;
        }

    }
};
</script>

<style scoped>

</style>
